import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { BreadcrumbRight } from "../../Assets/Icons";
import { useNavigate } from "react-router-dom";

const BreadCrumbs = ({ parentList, setParentList }) => {
  const navigate = useNavigate();

  const parentListClone = [...parentList];
  parentListClone?.reverse();

  const navigationHandler = (uuid) => {
    if (!uuid) {
      setParentList([]);
      navigate("/categories");
    } else {
      navigate(`/categories/${uuid}`);
    }
  };

  return (
    <div className="breadcrumb">
      {parentListClone.length > 0 && <p onClick={() => navigationHandler()}>Home</p>}
      {parentListClone.length > 2 && (
        <>
          <span>
            <BreadcrumbRight />
          </span>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              <p>....</p>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {parentListClone.map((item, index) => {
                if (index < parentListClone.length - 2) {
                  return <Dropdown.Item onClick={() => navigationHandler(item.uuid)}>{item.name}</Dropdown.Item>;
                }
              })}
            </Dropdown.Menu>
          </Dropdown>
        </>
      )}
      {parentListClone[parentListClone.length - 2] && (
        <>
          <span>
            <BreadcrumbRight />
          </span>
          <p onClick={() => navigationHandler(parentListClone[parentListClone.length - 2].uuid)}>
            {parentListClone[parentListClone.length - 2].name}
          </p>
        </>
      )}

      {parentListClone[parentListClone.length - 1] && (
        <>
          <span>
            <BreadcrumbRight />
          </span>
          <p className="active">{parentListClone[parentListClone.length - 1].name}</p>
        </>
      )}
    </div>
  );
};

export default BreadCrumbs;
