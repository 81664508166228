import React, { useEffect, useState } from "react";
import { FormControl, RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { TextField } from "@mui/material";
import Progress from "react-progressbar";
import { FileUploader } from "react-drag-drop-files";
import { CancelIcon } from "../../Assets/Icons";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  ASSET_UPLOAD_URL,
  CREATE_RESOURCE_URL,
  EDIT_RESOURCE_URL,
  LIST_RESOURCE_URL,
} from "../../apiClient/apiEndpoints";
import apiClient from "../../apiClient";
import { useParams } from "react-router-dom";
import moment from "moment";

const fileTypes = [".zip"];
const assetType = [
  {
    name: "Android Asset",
    key: "ANDROID",
  },
  {
    name: "Desktop Asset",
    key: "DESKTOP",
  },
  {
    name: "IOS Asset",
    key: "IOS",
  },
];

const emptyDefaultFile = {
  url: "",
  embedLink: "",
};

const formatSize = (bytes) => {
  if (bytes >= 1048576) {
    return (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    return (bytes / 1024).toFixed(2) + " KB";
  } else {
    return bytes + " bytes";
  }
};

const ProductsAssets = ({ productId }) => {
  const configData = useSelector((state) => state.config);
  const userData = useSelector((state) => state.user);
  const params = useParams();
  const [uploadFile, setUploadFile] = useState(null);
  const [comment, setComment] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(false);
  const [formError, setFormError] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(emptyDefaultFile);
  const [selectedAssetType, setSelectedAssetType] = useState("ANDROID");
  const [resourceList, setResourceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const API_URL = configData.config?.API_URL;
  const currentResource = resourceList.find((item) => item.type === selectedAssetType);

  const handleChangeUploadFile = (file) => {
    setError(false);
    setUploadFile(file);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("productId", productId);
    formData.append("type", selectedAssetType);
    axios
      .post(`${API_URL}${ASSET_UPLOAD_URL}`, formData, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          let progress = (progressEvent.loaded / progressEvent.total) * 100;
          if (progress < 100) {
            setUploadProgress(Math.floor(progress));
          }
        },
      })
      .then((res) => {
        setUploadProgress(100);
        setUploadedFile({ url: res.data.data?.url, embedLink: res.data.data?.embedLink });
      })
      .catch((err) => {
        setError(true);
        setUploadedFile(emptyDefaultFile);
        console.log(err);
      });
  };

  const handleUpload = async () => {
    if (!uploadedFile?.url || !comment?.trim()) {
      setFormError(true);
      return;
    }
    setFormError(false);
    setLoading(true);
    if (params?.productId) {
      const body = {
        url: uploadedFile.url,
        comment,
        productId: params.productId,
        type: selectedAssetType,
        embedLink: uploadedFile.embedLink,
      };
      await apiClient.put(`${API_URL}${EDIT_RESOURCE_URL}`, body);
    } else {
      const body = {
        url: uploadedFile.url,
        comment,
        productId,
        type: selectedAssetType,
        embedLink: uploadedFile.embedLink,
      };
      await apiClient.post(`${API_URL}${CREATE_RESOURCE_URL}`, body);
    }
    await fetchResourceList();
    setUploadFile(null);
    setComment("");
    setUploadProgress(0);
    setUploadedFile(emptyDefaultFile);
    setLoading(false);
  };

  const fetchResourceList = async () => {
    const list = await apiClient.get(`${API_URL}${LIST_RESOURCE_URL}/${params.productId}`);
    setResourceList(list);
  };

  useEffect(() => {
    if (params?.productId) {
      fetchResourceList();
    }
  }, [params.productId]);

  return (
    <div className="assets-page">
      <div className="popupselect fortype">
        <p>Select File Type</p>
        <FormControl fullWidth className="mb-3 pt-2 ms-3 d-flex align-items-start">
          <RadioGroup
            row
            aria-labelledby="asset-type-label"
            name="assetType"
            className="gap-3"
            value={selectedAssetType}
            onChange={(e) => setSelectedAssetType(e.target.value)}
          >
            {assetType.map((element, index) => (
              <FormControlLabel
                key={index}
                className="asset-label"
                value={element.key}
                control={<Radio />}
                label={element.name}
                checked={selectedAssetType === element.key}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
      <div className="row mt-4">
        <div className="col-lg-6">
          <div className="DragDropMenu uploadfilesss">
            <div className="fileuploader">
              <FileUploader
                handleChange={handleChangeUploadFile}
                label={
                  uploadFile?.name ? "File selected: " + uploadFile.name : "Drag & drop or choose a file to upload"
                }
                name="file"
              >
                <span className="upload-box-info">Drag & drop or choose a {`${fileTypes}`} file to upload</span>
              </FileUploader>
            </div>
          </div>

          {uploadFile && (
            <div className="uploading">
              <div className="uploadstatus">
                <div className="uploaddata">
                  <div className="name-cancel">
                    <p>{uploadFile.name}</p>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setUploadFile(null);
                        setUploadProgress(0);
                      }}
                    >
                      <CancelIcon />
                    </span>
                  </div>
                  <div className="file-size">
                    <p>{formatSize(uploadFile?.size)}</p>
                    <p>{uploadProgress}%</p>
                  </div>
                  <Progress completed={uploadProgress} />
                </div>
              </div>

              {error && (
                <div className="uploadProgressBar">
                  <p className="basicDetailError"> Error in file upload. try again </p>
                </div>
              )}
            </div>
          )}

          <div className="form-group des">
            <p style={{ paddingTop: "10px" }}>
              Comment <span>*</span>
            </p>
            <TextField
              name="comment"
              variant="outlined"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              fullWidth
              placeholder="Enter Comment"
              multiline
              rows={5}
              className="dessss"
              size="small"
            />
          </div>
          {formError && <p className="basicDetailError">Please fill all the required fields.</p>}
        </div>

        {currentResource && (
          <div className="col-lg-6">
            <div className="last-update">
              <div className="deploy-card">
                <p>{currentResource?.updatedAt ? moment(currentResource.createdAt).fromNow() : null}</p>
                <div className="filedetails">
                  <p>
                    Asset :
                    <span>
                      <a href={currentResource?.data?.url} download>
                        {currentResource?.data?.url?.split("/")[currentResource?.data?.url?.split("/")?.length - 1]}
                      </a>
                    </span>
                  </p>
                  <p>
                    Timestamp :<span>{moment(currentResource?.updatedAt).format("DD-MMM-YYYY ( HH:mm )")}</span>
                  </p>
                  <span className="deploy-comment">{currentResource?.data?.comment}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {loading ? (
          <button className="loading save">
            <svg viewBox="25 25 50 50">
              <circle r="20" cy="50" cx="50">
                {""}
              </circle>
            </svg>
          </button>
        ) : (
          <button type="button" className="createbtn" onClick={handleUpload}>
            Upload
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductsAssets;
