import React, { useEffect, useState } from "react";
import { CancelIcon, CloseIcon, SearchesCIcon } from "../../Assets/Icons";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import apiClient from "../../apiClient";
import LottiePlayer from "lottie-react";
import nodatafound from "../../Assets/Animation/nodatafound.json";
import { useNavigate, useParams } from "react-router-dom";
import { getNameInitials } from "../../utils/common";
import Filter from "../Common/Filter";

const filterGroups = (filter, source) => {
  if (!filter.length) return true;

  const filterObject = {};
  filter.forEach((item) => {
    if (item.key in filterObject) {
      filterObject[item.key].push(item.value);
    } else {
      filterObject[item.key] = [item.value];
    }
  });

  return Object.keys(filterObject || {})
    .map((key) => {
      return filterObject[key].includes(source[key]);
    })
    .reduce((init, value) => init || value, false);
};

const GroupsTab = ({ setActiveTab }) => {
  const loadBox = 4;
  const tileCards = [];
  const navigate = useNavigate();
  const { groupId } = useParams();
  const configData = useSelector((state) => state.config);
  const UNO_URL = configData.config.UNO_URL;
  const [loading, setLoading] = useState(true);
  const [groupList, setGroupList] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const filteredGroupList = groupList?.filter(
    (grp) =>
      (search ? grp.name.toLowerCase().includes(search?.toLowerCase()) : true) && filterGroups(selectedCheckboxes, grp)
  );

  const fetchGroupList = async () => {
    const list = await apiClient.get(`${UNO_URL}/api/user/group/list/${groupId}`);
    setGroupList(list);
  };

  const fetchGroupTypeList = async () => {
    const list = await apiClient.get(`${UNO_URL}/api/role/grouptype/list`);
    const options = list?.groupTypes.map((item) => ({
      name: item?.name,
      key: "typeId",
      value: item.id,
    }));
    setFilterOptions([{ name: "Group Type", tags: options }]);
  };

  const navigationHandler = (uuid) => {
    navigate(`/dashboard/${uuid}`);
    setActiveTab("dashboard");
  };

  const initialize = async () => {
    await Promise.all([fetchGroupList(), fetchGroupTypeList()]);
    setLoading(false);
  };

  const handleFilterClear = (value) => {
    const filteredList = selectedCheckboxes.filter((filter) => filter.value !== value);
    setSelectedCheckboxes(filteredList);
  };

  useEffect(() => {
    initialize();
  }, [groupId]);

  for (let i = 0; i < loadBox; i++) {
    tileCards.push(
      <div key={`tile-${i}`} className="tilecard p-0 mb-3" style={{ outline: "none" }}>
        <div className="groupcardesign">
          <div className="avatarimg p-0" style={{ outline: "none" }}>
            <Skeleton count={1} circle height="100%" width="100%" className="m-0 p-0" />
          </div>
          <h6 className="groupname"> </h6>
          <div className="groupusercount d-flex flex-column gap-2">
            <p>
              <Skeleton width="10vw" className="my-1" />{" "}
            </p>
            <p>
              <Skeleton width="10vw" className="my-1" />{" "}
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="container-fluid">
        <div className="row pt-4 mt-3">
          <div className="col-md-12 d-flex align-items-center justify-content-end">
            <form className=" app-search app-search1 d-flex justify-content-start me-2">
              <div className="position-relative">
                <SearchesCIcon />
                {search.length > 0 && (
                  <span className="searchiconcancel" onClick={() => setSearch("")}>
                    <CancelIcon />
                  </span>
                )}
                <input
                  type="text"
                  name="searchName"
                  className="form-control"
                  placeholder="Search"
                  autoComplete="off"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </form>
            <Filter
              selectedCheckboxes={selectedCheckboxes}
              setSelectedCheckboxes={setSelectedCheckboxes}
              filterOptions={filterOptions}
            />
          </div>
        </div>
        {selectedCheckboxes.length > 0 && (
          <div className="filerlist mt-3">
            <span>Filters:</span>
            {selectedCheckboxes.map((f) => {
              return (
                <span className="filtertag" key={f.value}>
                  {f.name}
                  <span onClick={() => handleFilterClear(f.value)}>
                    <CloseIcon />
                  </span>
                </span>
              );
            })}
            <span className="filtertag clear" onClick={() => setSelectedCheckboxes([])} style={{ cursor: "pointer" }}>
              Clear all
            </span>
          </div>
        )}
        <div className="courses-parent">
          {loading ? (
            <div className="course-tiles mt-2">{tileCards}</div>
          ) : (
            <>
              {filteredGroupList?.length > 0 ? (
                <div className="course-tiles mt-2">
                  {filteredGroupList?.map((grp) => (
                    <div className="tilecard p-0 mb-3" key={grp.uuid} onClick={() => navigationHandler(grp.uuid)}>
                      <div className="groupcardesign">
                        {grp.avatar ? (
                          <div className="avatarimg">
                            <img src={grp.avatar} alt="Group Logo" />
                          </div>
                        ) : (
                          <div className="initials-placeholder">{getNameInitials(grp.name || "")}</div>
                        )}
                        <h6 className="groupname"> {grp.name} </h6>
                        <div className="groupusercount">
                          <p>
                            {grp.groups} Sub-Groups <span className="mx-1">|</span>
                          </p>
                          <p> {grp.users} Users </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center mt-5">
                  <LottiePlayer animationData={nodatafound} style={{ height: 200 }} autoplay loop />
                  <h5>No Groups Found</h5>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GroupsTab;
