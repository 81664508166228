import React from "react";
import { SideNav, SimApps } from "../../Assets/Icons";
import { applicationId, defaultLogo } from "../../env";
import { useSelector } from "react-redux";
import axios from "axios";
import jwtEncode from "jwt-encode";
import { getNameInitials } from "../../utils/common";

const Header = ({ sideBar, setSideBar }) => {
  const { user, token } = useSelector((state) => state?.user);
  const applicationData = useSelector((state) => state?.application);
  const configData = useSelector((state) => state?.config);

  const logOut = async () => {
    localStorage.clear();
    sessionStorage.clear();
    await axios.post(
      `${configData?.config?.UNO_URL}/api/auth/logout`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    window.location.href = `${configData?.config?.redirectUri}`;
  };

  const redirectApplication = (link) => {
    if (token && link) {
      const payload = { token: token };
      const jwtToken = jwtEncode(payload, "");

      if (link.includes("apps.simulanis.io")) {
        const newUrl = `${link}`;
        window.location.href = newUrl;
      } else {
        const newUrl = `${link}?referrer=${jwtToken}`;
        window.location.href = newUrl;
      }
    }
  };

  return (
    <header>
      <div className="sidetoggle">
        <div
          className={sideBar ? "ps-1 activebar sidesvgdiv" : " sidesvgdiv ps-1"}
          onClick={() => {
            setSideBar(!sideBar);
            if (sideBar) {
              document.body.classList.add("body-lock");
            } else {
              document.body.classList.remove("body-lock");
            }
          }}
          style={{ width: "25px", height: "25px" }}
        >
          <SideNav />
        </div>
        <div className="mainlogo LOGOHERE">
          <img
            src={configData?.config?.logo}
            onError={(e) => {
              e.target.src = defaultLogo;
              e.onerror = null;
            }}
            referrerPolicy={"no-referrer"}
            alt=""
          />
          <p>{configData?.config?.name}</p>
        </div>
      </div>

      <div className="leftmain">
        <div className="profile">
          <div className="dropdown profileavatar">
            <p className="d-none d-md-block">{user?.name}</p>
            <button
              className="btn btn-secondary dropdown-toggle themetoggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="useravatar">
                {user?.avatar ? (
                  <img
                    src={user.avatar}
                    alt="Profile Image"
                    title={null}
                    className="profilepik"
                    referrerPolicy={"no-referrer"}
                  />
                ) : (
                  <div className="avatar-placeholder">{getNameInitials(user?.name) || ""}</div>
                )}
              </div>
            </button>
            <div className="dropdown-menu themehere popupon">
              <div className="profilemenuhead" tabIndex="0">
                {user?.avatar ? (
                  <img
                    src={user.avatar}
                    alt="Profile Image"
                    title={null}
                    className="profilepik"
                    referrerPolicy={"no-referrer"}
                  />
                ) : (
                  <div className="avatar-placeholder">{getNameInitials(user?.name) || ""}</div>
                )}
                <div className="profilemenudata">
                  <p>{user?.name}</p>
                  <p> {user?.email} </p>
                </div>
              </div>
              <p className="logoutbtn" onClick={logOut}>
                Log out
              </p>
            </div>
            <div className="dropdown profileavatar appnavigator">
              <button
                className="btn btn-secondary dropdown-toggle themetoggle "
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <SimApps />
              </button>
              <div className="dropdown-menu themehere popupon">
                <div className="menu-long">
                  <div className={applicationData?.apps?.length > 2 ? "menubg extraspace" : "menubg"}>
                    {applicationData?.apps?.map((e, i) => {
                      if (e.uuid === applicationId) return null;
                      return (
                        <div
                          onClick={() => redirectApplication(e.channel?.origins[0]?.url)}
                          key={e.uuid}
                          className="appshere"
                        >
                          <img
                            src={e.icon || defaultLogo}
                            onError={(e) => {
                              e.target.src = { defaultLogo };
                              e.onerror = null;
                            }}
                            alt=""
                            referrerPolicy={"no-referrer"}
                          />
                          <p>{e.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
