import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import {
  BackIcon,
  PDFDownloadIcon,
  ProductStatIcon,
  SpinnerIcon,
  StoreStatIcon,
  EXLDownloadIcon,
  EnlargeIcon,
} from "../../Assets/Icons";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import DateRangeSelector from "../filterDataRange/DateRangeSelector";
import BOM from "../Charts/BOM";
import Tooltip from "@mui/material/Tooltip";
import AppUsage from "../Charts/AppUsage";
import PlatformUsage from "../Charts/PlatformUsage";
import GroupsTab from "./GroupsTab";
import StateWise from "../Charts/StateWise";
import { useSelector } from "react-redux";
import {
  countNestedGroup,
  DASHBOARD_REPORT_NAME,
  generateCombinedDataExcel,
  getGroupIdList,
  getNameInitials,
} from "../../utils/common";
import apiClient from "../../apiClient";
import {
  FETCH_DASHBOARD_GRAPH_STATS,
  FETCH_DASHBOARD_STATS,
  FETCH_GROUP_GRAPH_STATS,
  FETCH_GROUP_HIERARCHY,
  FETCH_PLATFORM_GRAPH_STATS,
  FETCH_TIME_GRAPH_STATS,
} from "../../apiClient/apiEndpoints";
import { exportMultipleChartsToPdf } from "../../utils/pdf";

const Dashboard = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("dashboard");
  const userData = useSelector((state) => state.user);
  const configData = useSelector((state) => state.config);
  const [groupData, setGroupData] = useState({});
  const [stats, setStats] = useState({});
  const [bomGraphStats, setBomGraphStats] = useState([]);
  const [groupHierarchy, setGroupHierarchy] = useState({});
  const [timeGraphStats, setTimeGraphStats] = useState([]);
  const [platformGraphStats, setPlatformGraphStats] = useState({});
  const [groupGraphStats, setGroupGraphStats] = useState({});
  const API_URL = configData?.config?.API_URL;
  const [reportLoading, setReportLoading] = useState({
    excel: false,
    pdf: false,
  });

  const fetchGroupData = async () => {
    const res = await apiClient.get(`${configData.config.UNO_URL}/api/user/group/details/${groupId}`);
    setGroupData(res);
  };

  const fetchStats = async (groupIdList) => {
    const body = {};
    body.groupList = groupIdList;
    if (filter) {
      body.filter = filter;
    }
    const res = await apiClient.post(`${API_URL}${FETCH_DASHBOARD_STATS}`, body);
    setStats(res);
  };

  const fetchGraphStats = async (groupIdList) => {
    const body = {};
    body.groupList = groupIdList;
    if (filter) {
      body.filter = filter;
    }
    const res = await apiClient.post(`${API_URL}${FETCH_DASHBOARD_GRAPH_STATS}`, body);
    setBomGraphStats(res);
  };

  const fetchGroupGraphStats = async (groupIdList) => {
    const body = {};
    body.groupList = groupIdList;
    if (filter) {
      body.filter = filter;
    }
    const res = await apiClient.post(`${API_URL}${FETCH_GROUP_GRAPH_STATS}`, body);
    setGroupGraphStats(res);
  };

  const fetchTimeGraphStats = async (groupIdList) => {
    const body = {};
    body.groupList = groupIdList;
    if (filter) {
      body.filter = filter;
    }
    const res = await apiClient.post(`${API_URL}${FETCH_TIME_GRAPH_STATS}`, body);
    setTimeGraphStats(res);
  };

  const fetchPlatformGraphStats = async (groupIdList) => {
    const body = {};
    body.groupList = groupIdList;
    if (filter) {
      body.filter = filter;
    }
    const res = await apiClient.post(`${API_URL}${FETCH_PLATFORM_GRAPH_STATS}`, body);
    setPlatformGraphStats(res);
  };

  const fetchGroupHierarchy = async () => {
    const res = await apiClient.get(`${API_URL}${FETCH_GROUP_HIERARCHY}/${groupId}`);
    const list = getGroupIdList([res] || []);
    setGroupHierarchy(res);
    return list;
  };

  const generateReport = () => {
    setReportLoading((prev) => ({ ...prev, excel: true }));
    const reportData = [
      {
        "Group Name": groupData?.name,
        "Total Products": stats?.productCount,
        "Total Groups": countNestedGroup(groupHierarchy?.children || []),
        "Total Cost": ` Rs. ${new Intl.NumberFormat("en-IN").format(stats?.totalPrice)}`,
        "Total Boms": stats?.bomCount,
        "Total Sessions": Object.values(platformGraphStats || {}).reduce((init, item) => init + item, 0),
      },
    ];
    generateCombinedDataExcel(DASHBOARD_REPORT_NAME, [reportData]);
    setReportLoading((prev) => ({ ...prev, excel: false }));
  };

  const generatePdfReport = async () => {
    setReportLoading((prev) => ({ ...prev, pdf: true }));
    const reportData = [
      ["Group Dashboard", ""],
      ["Group Name", groupData?.name],
      ["Total Products", stats?.productCount],
      ["Total Groups", countNestedGroup(groupHierarchy?.children || [])],
      ["Total Cost", ` Rs. ${new Intl.NumberFormat("en-IN").format(stats?.totalPrice)}`],
      ["Total Boms", stats?.bomCount],
      ["Total Sessions", Object.values(platformGraphStats || {}).reduce((init, item) => init + item, 0)],
    ];
    await exportMultipleChartsToPdf(DASHBOARD_REPORT_NAME, reportData);
    setReportLoading((prev) => ({ ...prev, pdf: false }));
  };

  const initializePage = async () => {
    setLoading(true);
    const groupList = await fetchGroupHierarchy();
    await Promise.all([
      fetchGroupData(),
      fetchStats(groupList),
      fetchGraphStats(groupList),
      fetchGroupGraphStats(groupList),
      fetchPlatformGraphStats(groupList),
      fetchTimeGraphStats(groupList),
    ]);
    setLoading(false);
  };

  useEffect(() => {
    initializePage();
  }, [groupId, filter]);

  return (
    <div className="main-dashboard">
      <Tab.Container id="my-tabs" activeKey={activeTab} defaultActiveKey="dashboard">
        <Nav className="updated toptab" variant="tabs">
          <Nav.Item>
            <Nav.Link onClick={() => setActiveTab("dashboard")} eventKey="dashboard">
              Dashboards
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link onClick={() => setActiveTab("groups")} eventKey="groups">
              Groups
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content className="pt-3 ">
          <Tab.Pane eventKey="dashboard">
            <div className="container-fluid mb-0 pb-2 pt-4 px-3 mt-2">
              <div className="row ">
                <div className="col-md-6 d-flex align-items-center">
                  {userData?.user?.group?.uuid !== groupId && (
                    <button className="backbtn cursor-pointer ps-2 mt-2" title="Back" onClick={() => navigate(-1)}>
                      <BackIcon /> Back
                    </button>
                  )}
                </div>
                <div className="col-md-6  d-flex gap-2 align-items-center">
                  <DateRangeSelector selected={filter} setSelected={setFilter} />
                  <button
                    className="DefaultRedBtn download px-2"
                    title="Download PDF"
                    onClick={generatePdfReport}
                    disabled={reportLoading.pdf}
                  >
                    {reportLoading.pdf ? <SpinnerIcon /> : <PDFDownloadIcon />}
                  </button>
                  <button
                    className="DefaultRedBtn download px-2"
                    title="Download Excel"
                    onClick={generateReport}
                    disabled={reportLoading.excel}
                  >
                    {reportLoading.excel ? <SpinnerIcon /> : <EXLDownloadIcon />}
                  </button>
                </div>
              </div>
              <div className="row pt-2">
                <div className="col-lg-9 pe-2">
                  <div className="datacardesign">
                    <div className="row w-100 h-100 justify-content-between">
                      <div className="col-md-8 d-flex align-items-center ">
                        <div className="cardimages">
                          {loading ? (
                            <Skeleton count={1} circle height="5.5rem" width="5.5rem" />
                          ) : (
                            <>
                              {groupData?.avatar ? (
                                <div className="avatarimg">
                                  <img src={groupData.avatar} />
                                </div>
                              ) : (
                                <div className="initials-placeholder">{getNameInitials(groupData?.name || "")}</div>
                              )}
                            </>
                          )}
                          <h4 className="group-user-name m-0">
                            {loading ? <Skeleton count={1} width="12rem" /> : <> {groupData?.name} </>}
                          </h4>
                          {/* )} */}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="dashstatdata">
                          <div className="welcometext ">
                            <p>
                              {loading ? (
                                <Skeleton width="5vw" />
                              ) : (
                                <>
                                  {" "}
                                  <ProductStatIcon /> Products{" "}
                                </>
                              )}{" "}
                            </p>
                            <h4>{loading ? <Skeleton width="5vw" /> : <> {stats?.productCount} </>} </h4>
                          </div>
                          <div className="welcometext">
                            <p>
                              {loading ? (
                                <Skeleton width="5vw" />
                              ) : (
                                <>
                                  {" "}
                                  <StoreStatIcon /> Group{" "}
                                </>
                              )}{" "}
                            </p>
                            <h4>
                              {loading ? (
                                <Skeleton width="5vw" />
                              ) : (
                                <> {countNestedGroup(groupHierarchy?.children || [])} </>
                              )}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ps-2 col-lg-3">
                  <div className="datacardesign invoice">
                    <div className="small-detail">
                      <span>
                        {loading ? (
                          <Skeleton width="10vw" height="1vw" />
                        ) : (
                          <>
                            {" "}
                            <p>
                              {" "}
                              Total Cost
                              {stats?.bomCount > 0 && (
                                <Tooltip title="View Details">
                                  <span className="EnlargeIcon" onClick={() => navigate(`/bom-list/${groupId}`)}>
                                    <EnlargeIcon />
                                  </span>
                                </Tooltip>
                              )}
                            </p>
                          </>
                        )}

                        <h5>
                          {" "}
                          {!loading && <> &#8377; {new Intl.NumberFormat("en-IN").format(stats?.totalPrice)} </>}
                        </h5>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-lg-12 pe-2">
                  <div className="chart-box">
                    <div className="chart-info">
                      <p> {loading ? <Skeleton width="15vw" /> : <> BOM Requested </>}</p>
                      <span>
                        {loading ? (
                          <Skeleton width="15vw" />
                        ) : (
                          <>
                            {" "}
                            Total BOMs : <strong>{stats?.bomCount}</strong>{" "}
                            {stats?.bomCount > 0 && (
                              <Tooltip title="View BOMs List">
                                <span className="EnlargeIcon" onClick={() => navigate(`/bom-list/${groupId}`)}>
                                  <EnlargeIcon />
                                </span>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </span>
                    </div>
                    <BOM graphData={bomGraphStats} />
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className="col-lg-9">
                  <div className="row">
                    <div className="col-lg-6 ps-2 pe-2">
                      <div className="chart-box">
                        <div className="chart-info">
                          <p> {loading ? <Skeleton width="10rem" /> : <> Application Usage </>} </p>
                          <span>
                            {loading ? (
                              <Skeleton width="5rem" />
                            ) : (
                              <>
                                {" "}
                                Average :{" "}
                                <strong>
                                  {timeGraphStats?.reduce((init, item) => init + item.count, 0) /
                                    timeGraphStats?.length}
                                </strong>{" "}
                              </>
                            )}
                          </span>
                        </div>
                        <AppUsage graphStats={timeGraphStats} />
                      </div>
                    </div>
                    <div className="col-lg-6 pe-2 ps-2">
                      <div className="chart-box">
                        <div className="chart-info">
                          <p> {loading ? <Skeleton width="10rem" /> : <> Group Wise Usage </>} </p>
                          <span>
                            {loading ? (
                              <Skeleton width="5rem" />
                            ) : (
                              Object.values(groupGraphStats || {})?.length && (
                                <>
                                  {" "}
                                  Average :{" "}
                                  <strong>
                                    {" "}
                                    {Object.values(groupGraphStats || {})?.reduce((init, item) => init + item, 0) /
                                      Object.values(groupGraphStats || {})?.length}{" "}
                                  </strong>{" "}
                                </>
                              )
                            )}
                          </span>
                        </div>
                        <StateWise graphStats={groupGraphStats} groupHierarchy={groupHierarchy} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 ps-2">
                  <div className="chart-box">
                    <div className="chart-info">
                      <p> {loading ? <Skeleton width="10vw" /> : <> Sessions </>}</p>
                      <span>
                        {loading ? (
                          <Skeleton width="5vw" />
                        ) : (
                          <>
                            {" "}
                            Total :{" "}
                            <strong>
                              {Object.values(platformGraphStats || {}).reduce((init, item) => init + item, 0)}
                            </strong>{" "}
                            {Object.values(platformGraphStats || {}).reduce((init, item) => init + item, 0) > 0 && (
                              <Tooltip title="View Sessions List">
                                <span className="EnlargeIcon" onClick={() => navigate(`/sessions-list/${groupId}`)}>
                                  <EnlargeIcon />
                                </span>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </span>
                    </div>
                    <PlatformUsage graphStats={platformGraphStats} />
                  </div>
                </div>
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="groups">
            <GroupsTab setActiveTab={setActiveTab} />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default Dashboard;