import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slice/userSlice";
import applicationSlice from "./slice/applicationSlice";
import configSlice from "./slice/configSlice";
import pageSlice from "./slice/paginationSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    application: applicationSlice,
    config: configSlice,
    page: pageSlice,
  },
});
