import React, { useEffect, useState } from "react";
import { CancelIcon, EditIcon, EXLDownloadIcon, SearchesCIcon, SpinnerIcon } from "../../Assets/Icons";
import FormControlLabel from "@mui/material/FormControlLabel";
import NoDataFoundAnim from "../../Assets/Animation/nodatafound.json";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddCategory from "../Modals/AddCategory";
import Filter from "../Common/Filter";
import apiClient from "../../apiClient";
import { useDispatch, useSelector } from "react-redux";
import {
  EDIT_CATEGORY_URL,
  FETCH_CATEGORY_LIST,
  FETCH_CATEGORY_REPORT,
  FETCH_PARENT_CATEGORIES,
} from "../../apiClient/apiEndpoints";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Pagination from "@mui/material/Pagination";
import LottiePlayer from "lottie-react";
import BreadCrumbs from "../Common/BreadCrumbs";
import IOSSwitch from "../Common/Switch";
import { CateCSVUpload } from "../Common/CateCSVUpload";
import {
  CATEGORY_REPORT_NAME,
  changePageNumber,
  createTreeFromBreadcrumb,
  findPageNumber,
  generateExcel,
  getNameInitials,
} from "../../utils/common";
import { saveCategoryTree, saveRootCategory } from "../../store/slice/paginationSlice";

const filterOptions = [
  {
    name: "Status",
    tags: [
      { name: "Active", key: "active", value: true },
      { name: "Inactive", key: "active", value: false },
    ],
  },
];

const Categories = () => {
  const configData = useSelector((state) => state.config);
  const { categoryTree, rootCategory } = useSelector((state) => state.page);
  const dispatch = useDispatch();
  const [categoryList, setCategoryList] = useState({});
  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [loading, setLoading] = useState(true);
  const [editData, setEditData] = useState(null);
  const [slug, setSlug] = useState("");
  const [AddCate, setAddCate] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [activeStatus, setActiveStatus] = useState({});
  const [breadCrumbList, setBreadCrumbList] = useState([]);
  const [csvUpload, setCsvUpload] = useState(false);
  const API_URL = configData?.config?.API_URL;
  const [reportLoading, setReportLoading] = useState({
    download: false,
    upload: false,
  });

  const fetchCategoryList = async () => {
    const queryParams = {};
    if (categoryId) {
      queryParams.categoryId = categoryId;
    }
    if (slug) {
      queryParams.query = slug;
    }
    if (selectedCheckboxes.length === 1) {
      queryParams[selectedCheckboxes[0].key] = selectedCheckboxes[0].value;
    }
    queryParams.pageSize = configData.config?.DEFAULT_PAGE_SIZE || 10;
    queryParams.pageNumber = pageNumber;
    const res = await apiClient.get(`${API_URL}${FETCH_CATEGORY_LIST}`, { params: queryParams });
    setLoading(false);
    setCategoryList(res);
    const activityStatusObj = {};
    res?.records?.map((item) => {
      activityStatusObj[item.uuid] = item.active;
    });
    setActiveStatus(activityStatusObj);
  };

  const fetchParentCategories = async () => {
    if (categoryId) {
      const res = await apiClient.get(`${API_URL}${FETCH_PARENT_CATEGORIES}/${categoryId}`);
      if (res) {
        setBreadCrumbList(res);
      }
    }
  };

  const editClickHandler = (uuid) => {
    const category = categoryList?.records?.find((item) => item.uuid === uuid);
    setEditData(category);
    setAddCate(true);
  };

  const toggleChangeHandler = async (uuid, toggleStatus) => {
    const categoryToEdit = categoryList.records.find((value) => value.uuid === uuid);
    const body = {
      name: categoryToEdit.name,
      description: categoryToEdit.data?.description,
      active: toggleStatus,
      parentId: categoryId ? categoryId : null,
      image: categoryToEdit.data?.image,
    };
    const stringifiedBody = JSON.stringify(body);
    const form = new FormData();
    form.append("data", stringifiedBody);
    const res = await apiClient.put(`${API_URL}${EDIT_CATEGORY_URL}/${uuid}`, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (res) {
      fetchCategoryList();
    }
  };

  const searchHandler = (e) => {
    setSlug(e.target.value);
  };

  useEffect(() => {
    if (pageNumber) {
      fetchCategoryList();
      fetchParentCategories();
    }
  }, [categoryId, pageNumber, slug, selectedCheckboxes]);

  const openCsvModal = () => {
    setCsvUpload(true);
  };

  const hideCsvModal = () => {
    setCsvUpload(false);
  };

  const generateCategoryReport = async () => {
    setReportLoading((prev) => ({ ...prev, download: true }));
    const res = await apiClient.get(`${API_URL}${FETCH_CATEGORY_REPORT}`);
    const reportData = res.map((item, idx) => ({
      "S.No.": idx + 1,
      Name: item?.name,
      Description: item?.data?.description,
      Uuid: item?.uuid,
      ParentCategory: item?.parentCategory?.uuid,
      Status: item?.active ? 1 : 0,
      Image: item?.data?.image,
    }));
    generateExcel(CATEGORY_REPORT_NAME, reportData);
    setReportLoading((prev) => ({ ...prev, download: false }));
  };

  useEffect(() => {
    if (categoryId) {
      const cloneTree = [...breadCrumbList];
      cloneTree.reverse();
      const updatedCategoryTree = createTreeFromBreadcrumb(categoryTree, cloneTree);
      dispatch(saveCategoryTree(updatedCategoryTree));
      const number = findPageNumber(updatedCategoryTree, categoryId);
      setPageNumber(number);
    } else {
      setPageNumber(rootCategory);
      dispatch(saveCategoryTree([]));
    }
  }, [breadCrumbList, categoryId]);

  const pageChangeHandler = (value) => {
    if (categoryId) {
      const updatedTree = changePageNumber([...categoryTree], categoryId, value);
      dispatch(saveCategoryTree(updatedTree));
      setPageNumber(value);
    } else {
      dispatch(saveRootCategory(pageNumber));
    }
  };

  return (
    <>
      <div className="category-main">
        <div className="page-tile">
          <h5>Categories</h5>
          {(categoryList?.records?.length > 0 || slug || selectedCheckboxes.length > 0) && (
            <div className="searchnadd">
              <form className=" app-search app-search1 d-flex justify-content-start ">
                <div className="position-relative w-100">
                  <SearchesCIcon />
                  {slug.length > 0 && (
                    <span
                      className="searchiconcancel"
                      onClick={() => {
                        setSlug("");
                      }}
                    >
                      <CancelIcon />
                    </span>
                  )}

                  <input
                    type="text"
                    name="searchName"
                    className="form-control"
                    placeholder="Search Categories"
                    autoComplete="off"
                    value={slug}
                    onChange={searchHandler}
                  />
                </div>
              </form>
              <Filter
                selectedCheckboxes={selectedCheckboxes}
                setSelectedCheckboxes={setSelectedCheckboxes}
                filterOptions={filterOptions}
              />
              <button className="classic-btn" onClick={() => setAddCate(true)}>
                Add Category
              </button>
              <div className="csvuploaddownload">
                <button type="button" onClick={openCsvModal} className="uloadoanload">
                  <FileUploadOutlinedIcon />
                  <span>CSV</span>
                </button>
              </div>
              <button
                className="DefaultRedBtn download px-2"
                title="Download Excel"
                onClick={generateCategoryReport}
                disabled={reportLoading?.download}
              >
                {reportLoading.download ? <SpinnerIcon /> : <EXLDownloadIcon />}
              </button>
            </div>
          )}
        </div>
        <BreadCrumbs parentList={breadCrumbList} setParentList={setBreadCrumbList} />

        {loading ? (
          <>
            {
              <div className="tablenpage">
                <div className="table-responsive cate-table p-0 ">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center">S.No.</th>
                        <th className="text-center">Image</th>
                        <th>Category Name</th>
                        <th>Description</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {[...Array(4)].map((_, index) => (
                        <tr key={index}>
                          <td>
                            <div className="table-text">
                              <Skeleton animation="wave" variant="text" height="2rem" width="2rem" />
                            </div>
                          </td>
                          <td>
                            <div className="table-text">
                              <Skeleton animation="wave" variant="text" height="6.8rem" width="7.5rem" />
                            </div>
                          </td>
                          <td>
                            <div className="table-text justify-content-start">
                              <Skeleton animation="wave" variant="text" height="2rem" width="8rem" />
                            </div>
                          </td>
                          <td>
                            <div className="table-text justify-content-start">
                              <Skeleton animation="wave" variant="text" height="2rem" width="25rem" />
                            </div>
                          </td>
                          <td>
                            <div className="table-text">
                              <Skeleton animation="wave" variant="text" height="2rem" width="5rem" />
                            </div>
                          </td>
                          <td>
                            <div className="table-text">
                              <Skeleton animation="wave" variant="text" height="2rem" width="4rem" />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            }
          </>
        ) : (
          <div className="tablenpage">
            <div className="table-responsive cate-table p-0 ">
              {categoryList?.records?.length > 0 ? (
                <>
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center">S.No.</th>
                        <th className="text-center">Image</th>
                        <th>Category Name</th>
                        <th>Description</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryList?.records?.map((item, index) => (
                        <tr key={item.uuid}>
                          <td>
                            <div className="table-text">{index + categoryList?.pagination?.firstRecord}</div>
                          </td>
                          <td className="text-center">
                            {item.data?.image ? (
                              <img src={item.data?.image} />
                            ) : (
                              <span className="img-ph">{getNameInitials(item.name)}</span>
                            )}
                          </td>
                          <td>
                            <div
                              className="table-text justify-content-start link-here"
                              onClick={() => navigate(`/categories/${item.uuid}`)}
                            >
                              {item?.name}
                            </div>
                          </td>
                          <td>
                            <div className="table-text justify-content-start des">
                              <p>{item.data?.description || "NA"}</p>
                            </div>
                          </td>
                          <td>
                            <div className="table-text">
                              <FormControlLabel
                                className="me-0"
                                name="active"
                                checked={activeStatus[item.uuid]}
                                onChange={(e) => {
                                  setActiveStatus((prev) => ({ ...prev, [item.uuid]: e.target.checked }));
                                  toggleChangeHandler(item.uuid, e.target.checked);
                                }}
                                control={<IOSSwitch sx={{ m: 1 }} />}
                              />
                              <span className={activeStatus[item.uuid] ? "active-label" : "inactive-label"}>
                                {activeStatus[item.uuid] ? "Active" : "Inactive"}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="table-text action-btn">
                              <span onClick={() => editClickHandler(item.uuid)}>
                                <EditIcon />
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-center">S.No.</th>
                        <th className="text-center">Image</th>
                        <th>Category Name</th>
                        <th>Description</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                  </table>
                  <div className="no-data-found" style={{ borderRadius: "0" }}>
                    <LottiePlayer
                      animationData={NoDataFoundAnim}
                      style={{ height: 150, marginBottom: 0, border: "none" }}
                    />
                    <p>{slug ? "No categories found matching your search." : "No categories yet"}</p>
                    {!slug && (
                      <button className="classic-btn" onClick={() => setAddCate(true)}>
                        Add Category
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="pagination">
              <div className="current-stat">
                {categoryList?.pagination?.firstRecord} - {categoryList?.pagination?.lastRecord} of{" "}
                {categoryList?.pagination?.totalRecords}
              </div>
              <div className="pages">
                <Pagination
                  count={categoryList?.pagination?.totalPage}
                  defaultPage={1}
                  page={pageNumber}
                  siblingCount={0}
                  onChange={(e, pageNumber) => pageChangeHandler(pageNumber)}
                  color="primary"
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: "#333",
                      backgroundColor: "#f0f0f0",
                    },
                    "& .MuiPaginationItem-root.Mui-selected": {
                      color: "#fff",
                      backgroundColor: "#ca373c",
                    },
                    "& .MuiPaginationItem-root:hover": {
                      backgroundColor: "#dcdcdc",
                    },
                  }}
                  boundaryCount={2}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <AddCategory
        AddCate={AddCate}
        setAddCate={setAddCate}
        refreshCategory={fetchCategoryList}
        categoryData={editData}
        setCategoryData={setEditData}
      />
      <CateCSVUpload
        showCSV={csvUpload}
        hideCsvModal={hideCsvModal}
        loading={reportLoading}
        setLoading={setReportLoading}
      />
    </>
  );
};

export default Categories;
