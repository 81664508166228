import React from "react";
import ReactApexChart from "react-apexcharts";
import useWindowDimensions from "../../utils/useWindowDimensions";

const BOM = ({ graphData }) => {
  let { width } = useWindowDimensions();
  const sampleChartData = {
    series: [
      {
        name: "BOM",
        data: graphData.map((item) => item.count),
      },
    ],
    options: {
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "straight",
        width: 2,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.7,
          opacityFrom: 0.5,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 1,
        },
      },
      xaxis: {
        categories: graphData.map((item) => item.label),
      },
      colors: ["#ca373c"],
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={sampleChartData.options}
        series={sampleChartData.series}
        type="area"
        height={width > 1400 && width < 1650 ? 210 : width >= 1650 ? 260 : 200}
      />
    </div>
  );
};

export default BOM;
