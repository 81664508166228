import React, { useState } from "react";
import { CancelIcon, FilterIcon, SearchesCIcon } from "../../Assets/Icons";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const Filter = ({ selectedCheckboxes, setSelectedCheckboxes, filterOptions }) => {
  const [showfilter, setShowFilter] = useState(false);
  const [sortingOpen, setSortingOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentTab, setCurrentTab] = useState(null);

  const handleCheckboxChange = (event, item) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedCheckboxes((prevSelected) => [...prevSelected, item]);
    } else {
      setSelectedCheckboxes((prevSelected) => prevSelected.filter((selectedItem) => selectedItem.value !== item.value));
    }
  };

  const isChecked = (item) => {
    return selectedCheckboxes?.find((value) => value.value === item.value) ? true : false;
  };

  const body = document.body;

  const openFilter = () => {
    setShowFilter(!showfilter);
    if (!showfilter) {
      body.classList.add("filter-active");
    } else {
      body.classList.remove("filter-active");
    }
  };

  const openSorting = () => {
    setSortingOpen(!sortingOpen);
  };

  const handleReset = () => {
    setSelectedCheckboxes([]);
    setSearchQuery("");
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getSelectedCount = (filterName) => {
    const selectedCount = selectedCheckboxes.filter((item) => {
      const filter = filterOptions.find((filter) => filter.name === filterName);
      return filter.tags.some((tag) => tag.name === item.name);
    }).length;

    // Display "9+" if the count exceeds 9
    return selectedCount > 9 ? "9+" : selectedCount.toString();
  };

  const renderCount = (filterName) => {
    const count = getSelectedCount(filterName);

    // Render the count only if there is anything selected
    return count !== "0" ? <span className="count">{count}</span> : null;
  };

  return (
    <>
      <button className="classic-btn for-filter" onClick={openFilter}>
        <FilterIcon />
        Filter
        {selectedCheckboxes.length ? <span className="numberhere">{selectedCheckboxes.length}</span> : null}
      </button>
      <div className={showfilter ? "filterbox p-4" : "filterbox inactive p-4"}>
        <div className="filterhead pt-2 pb-3">
          <p>Filter</p>
          <button onClick={handleReset}>Reset</button>
        </div>
        {filterOptions.map((filter, index) => (
          <div key={index} className={`createdfilter ${currentTab == index ? "createdactive" : ""}`}>
            <div className="createrhead" onClick={() => setCurrentTab(currentTab == index ? null : index)}>
              <span className="d-flex align-items-center justify-content-start gap-2">
                <p>{filter.name}</p>
                {renderCount(filter.name)}
              </span>
              <p>{currentTab == index ? <RemoveOutlinedIcon /> : <AddOutlinedIcon />}</p>
            </div>

            {currentTab == index ? (
              <>
                <>
                  <div className="searchfields form-group  position-relative mt-2 mb-1">
                    <span className="searchicon">
                      <SearchesCIcon />
                    </span>
                    <input
                      type="text"
                      placeholder="Search.."
                      className="form-control"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                    {searchQuery.length > 0 && (
                      <span className="filterSearchCancel searchiconcancel" onClick={() => setSearchQuery("")}>
                        <CancelIcon />
                      </span>
                    )}
                  </div>
                </>
                <div className="createdatahere mt-0 pe-2">
                  <FormGroup>
                    {filter.tags
                      .filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()))
                      .map((item, subIndex) => (
                        <FormControlLabel
                          key={subIndex}
                          checked={isChecked(item)}
                          onChange={(event) => handleCheckboxChange(event, item)}
                          control={<Checkbox />}
                          label={item.name}
                        />
                      ))}
                  </FormGroup>
                </div>
              </>
            ) : null}
          </div>
        ))}
      </div>

      {showfilter && <div className="showoverlay" onClick={openFilter}></div>}
      {sortingOpen && <div className="showoverlay" onClick={openSorting}></div>}
    </>
  );
};

export default Filter;
