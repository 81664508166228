import React from "react";
import ReactApexChart from "react-apexcharts";
import useWindowDimensions from "../../utils/useWindowDimensions";

const AppUsage = ({ graphStats }) => {
  let { width } = useWindowDimensions();
  const labels = graphStats?.map((item) => item?.label);
  const guidedCounts = graphStats?.map((item) => item?.count);

  const chartOptions = {
    chart: {
      type: "bar",
      width: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: labels,
      tickAmount: 15,
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    colors: ["#CA353B"],
  };

  const chartSeries = [
    {
      name: "Logins",
      data: guidedCounts,
    },
  ];

  return (
    <div id="chart">
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={width > 1400 && width < 1650 ? 210 : width >= 1650 ? 270 : 200}
      />
    </div>
  );
};

export default AppUsage;
