import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export const humanReadableMinutes = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  return minutes;
};

export const humanReadable = (seconds) => {
  const days = Math.floor(seconds / 86400);
  seconds %= 86400;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds = Math.floor(seconds % 60);
  const parts = [
    { label: 'Days', value: days },
    { label: 'Hrs', value: hours },
    { label: 'Mins', value: minutes },
    { label: 'Secs', value: seconds },
  ];
  const nonZeroParts = parts.filter(part => part.value > 0);
  const result = nonZeroParts.slice(0, 2).map(part => `${part.value} ${part.label}`).join(' ');
  return result || '0 Secs';
};

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const labels = {
  "MONTHLY": ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  "QUARTERLY": ['Q1', 'Q2', 'Q3', 'Q4'],
  "YEARLY": ['Current Year'],
}

export const kpiTypes = {
  MONTHLY : "MONTHLY",
  QUARTERLY : "QUARTERLY",
  YEARLY  : "YEARLY"
}

export const monitoringOptions = {
  "CUSTOM": 'Custom',
  "TOTAL_TRAININGS": 'Total Trainings',
  "ACTIVE_USERS": 'Active Users',
  "CERTIFIED_USERS": 'Certified Users'
};

export const graphTypes = {
  "BAR": "Bar Chart",
  "MIXED": "Mixed Chart",
  "LINE": "Line Chart"
}

export const cumulativeArray = (inputArray) => {
  if (!Array.isArray(inputArray)) {
    return [];
  }

  const cumulativeResult = [];
  let cumulativeSum = 0;

  for (let i = 0; i < inputArray.length; i++) {
    cumulativeSum += inputArray[i];
    cumulativeResult.push(cumulativeSum);
  }

  return cumulativeResult;
};

export const convertValuesToTarget = (array, targetType, inputType) => {
  if (inputType === kpiTypes.MONTHLY) {
    if (targetType === kpiTypes.MONTHLY) {
      // Month to Month (no change)
      return [...array];
    } else if (targetType === kpiTypes.QUARTERLY) {
      // Month to Quarter
      const result = [];
      for (let i = 0; i < 12; i += 3) {
        result.push(array.slice(i, i + 3).reduce((acc, val) => acc + val, 0));
      }
      return result;
    } else if (targetType === kpiTypes.YEARLY) {
      // Month to Year
      const result = [array.reduce((acc, val) => acc + val, 0)];
      return result;
    }
  } else if (inputType === kpiTypes.QUARTERLY) {
    if (targetType === kpiTypes.MONTHLY) {
      // Quarter to Month
      const result = [];
      for (let i = 0; i < 4; i++) {
        const quarterValue = array[i];
        for (let j = 0; j < 3; j++) {
          result.push(quarterValue / 3);
        }
      }
      return result;
    } else if (targetType === kpiTypes.QUARTERLY) {
      // Quarter to Quarter (no change)
      return [...array];
    } else if (targetType === kpiTypes.YEARLY) {
      // Quarter to Year
      const result = [array.reduce((acc, val) => acc + val, 0)];
      return result;
    }
  } else if (inputType === kpiTypes.YEARLY) {
    if (targetType === kpiTypes.MONTHLY) {
      // Year to Month
      const result = Array.from({ length: 12 }, () => array[0] / 12);
      return result;
    } else if (targetType === kpiTypes.QUARTERLY) {
      // Year to Quarter
      const result = Array.from({ length: 4 }, () => array[0] / 4);
      return result;
    } else if (targetType === kpiTypes.YEARLY) {
      // Year to Year (no change)
      return [...array];
    }
  }

  // Handle other input and target types if needed
  return [];
}

export const hoursMinuteList = () =>{
  const hours = [...Array(24 * 4)].map((_, index) => {
    const hours = Math.floor(index / 4);
    const minutes = (index % 4) * 15;
    const ampm = hours < 12 ? 'AM' : 'PM';
    const hour12 = hours % 12 || 12;
    return `${hour12 < 10 ? '0' : ''}${hour12}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;
   });
   return hours
}


export const platformName = {
  "WEB": "Online",
  "MOBILE": "Mobile",
  "DESKTOP": "Desktop",
  "DESKTOPVR": "Desktop VR",
  "STANDALONEVR": "Standalone VR",
  "MACOS" : "macOS"
};