import jsPDF from "jspdf";
import "jspdf-autotable";
import * as htmlToImage from "html-to-image";
import moment from "moment";

export async function exportMultipleChartsToPdf(name, tableData1, tableData2, tableData3) {
  const doc = new jsPDF("p", "px");
  const elements = document.getElementsByClassName("chart-box");

  try {
    await createPdf({ doc, elements, tableData1, tableData2, tableData3 });
    doc.save(`${name}_Report_${moment().format("MMMM Do YYYY, h:mm:ss a")}.pdf`);
  } catch (error) {
    console.error("Error exporting to PDF:", error);
  } finally {
    return true;
  }
}

export const createNonChartPdf = async (name, tableData1, tableData2, tableData3) => {
  try {
    const doc = new jsPDF("p", "px");
    const elements = [];
    await createPdf({ doc, elements, tableData1, tableData2, tableData3 });
    doc.save(`${name}_Report_${moment().format("MMMM Do YYYY, h:mm:ss a")}.pdf`);
  } catch (error) {
    console.error("Error exporting to PDF:", error);
  } finally {
    return true;
  }
};

async function createPdf({ doc, elements, tableData1, tableData2, tableData3 }) {
  const padding = 10;
  const marginTop = 20;
  let top = marginTop;

  addTable(doc, tableData1, top);
  top += calculateTableHeight(tableData1) + marginTop;

  for (let i = 0; i < elements?.length; i++) {
    const el = elements?.item(i);

    try {
      const imgData = await htmlToImage.toPng(el, { quality: 1.0, pixelRatio: 2 });

      let elHeight = el.offsetHeight;
      let elWidth = el.offsetWidth;

      const pageWidth = doc.internal.pageSize.getWidth();

      if (elWidth > pageWidth) {
        const ratio = pageWidth / elWidth;
        elHeight = elHeight * ratio - padding * 2;
        elWidth = elWidth * ratio - padding * 2;
      }

      const pageHeight = doc.internal.pageSize.getHeight();

      if (top + elHeight > pageHeight) {
        doc.addPage();
        top = marginTop;
      }

      const xPosition = (pageWidth - elWidth) / 2;

      doc.addImage(imgData, "PNG", xPosition, top, elWidth, elHeight, `image${i}`);
      top += elHeight + marginTop;
    } catch (error) {
      console.error("Error converting element to PNG:", error);
    }
  }

  if (tableData2) {
    addTable(doc, tableData2, top);
    top += calculateTableHeight(tableData2) + marginTop;
  }
  if (tableData3) {
    addTable(doc, tableData3, top);
  }
}

function addTable(doc, tableData, startY) {
  doc.autoTable({
    startY,
    head: [tableData[0]],
    body: tableData.slice(1),
  });
}

function calculateTableHeight(tableData) {
  const rowHeight = 20;
  return (tableData.length - 1) * rowHeight;
}
