import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./Components/AppRouter";
import { useDispatch } from "react-redux";
import axios from "axios";
import jwtEncode from "jwt-encode";
import { jwtDecode } from "jwt-decode";
import { AUTH_TOKEN } from "./utils/common";
import { saveConfig } from "./store/slice/configSlice";
import { saveUserData } from "./store/slice/userSlice";
import { applicationId, UNO_URL } from "./env";
import DefaultLayout from "./Components/Layout/DefaultLayout";
import FullScreenLoader from "./Components/FullScreenLoader/fullScreenLoader";

function App() {
  const [loading, setLoading] = useState(true);
  const [sideBar, setSideBar] = useState(false);

  const dispatch = useDispatch();

  const getConfiguration = async () => {
    await axios
      .get(`${UNO_URL}/api/application/configuration/${applicationId}`)
      .then((response) => {
        const data = response.data.data.data;
        dispatch(saveConfig({ data }));
        getUserDetail(data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getUserDetail = async (config) => {
    const urlParams = new URLSearchParams(window.location.search);
    const _tokenParam = urlParams.get("referrer");
    let token = "";
    var initialPath = null;
    if (_tokenParam) {
      const decoded = jwtDecode(_tokenParam);
      localStorage.setItem(AUTH_TOKEN, decoded.token);
      token = decoded.token;
      initialPath = decoded.redirectUri;
    } else if (localStorage.getItem(AUTH_TOKEN)) {
      token = localStorage.getItem(AUTH_TOKEN);
    }

    try {
      let response = await axios.get(`${config.UNO_URL}/api/user/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        localStorage.setItem(AUTH_TOKEN, response.data.data.token);
        const data = {
          token: response.data.data.token,
          user: response.data.data.user,
        };
        dispatch(saveUserData(data));
      }
    } catch (error) {
      localStorage.clear();
      sessionStorage.clear();
      const payload = {
        origin: window.location.origin,
        redirectUri: window.location.pathname,
      };
      const token = jwtEncode(payload, "");
      return (window.location.href = `${config.redirectUri}?referrer=${token}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConfiguration();
  }, []);

  return loading ? (
    <FullScreenLoader />
  ) : (
    <Router>
      <DefaultLayout sideBar={sideBar} setSideBar={setSideBar} />
      <div className={sideBar ? "main-content sideactive" : "main-content"}>
        <AppRouter />
      </div>
    </Router>
  );
}

export default App;
