import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TextField } from "@mui/material";
import { CloseIcon, DeleteIcon } from "../../Assets/Icons";
import DropZone from "../Common/DropZone";
import IOSSwitch from "../Common/Switch";
import apiClient from "../../apiClient";
import { useSelector } from "react-redux";
import { CREATE_NEW_CATEGORY_URL, EDIT_CATEGORY_URL } from "../../apiClient/apiEndpoints";
import { useParams } from "react-router-dom";
import { validateString } from "../../utils/common";

const defaultFormData = { name: "", description: "", active: true };

const AddCategory = ({ AddCate, setAddCate, refreshCategory, categoryData, setCategoryData }) => {
  const configData = useSelector((state) => state.config);
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [formData, setFormData] = useState(defaultFormData);
  const [formLoading, setFormLoading] = useState(false);
  const [error, setError] = useState(false);
  const API_URL = configData.config.API_URL;
  const { categoryId } = useParams();

  const submitHandler = async () => {
    if (!validateString(formData?.name)) {
      setError(true);
      return;
    }
    setError(false);
    setFormLoading(true);
    if (categoryData) {
      const body = { ...formData, parentId: categoryId ? categoryId : null, image: image ? null : previewImage };
      const stringifiedBody = JSON.stringify(body);
      const form = new FormData();
      if (image) {
        form.append("file", image);
      }
      form.append("data", stringifiedBody);
      await apiClient.put(`${API_URL}${EDIT_CATEGORY_URL}/${categoryData.uuid}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } else {
      const body = { ...formData, parentId: categoryId ? categoryId : null };
      const stringifiedBody = JSON.stringify(body);
      const form = new FormData();
      if (image) {
        form.append("file", image);
      }
      form.append("data", stringifiedBody);
      await apiClient.post(`${API_URL}${CREATE_NEW_CATEGORY_URL}`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
    closeModal();
    refreshCategory();
    setFormLoading(false);
  };

  const closeModal = () => {
    setAddCate(false);
    setFormData(defaultFormData);
    setImage(null);
    setPreviewImage("");
    setCategoryData(null);
    setError(false);
  };

  useEffect(() => {
    if (categoryData) {
      const data = {
        name: categoryData.name || "",
        description: categoryData.data?.description || "",
        active: categoryData.active,
      };
      setFormData(data);
      setPreviewImage(categoryData.data?.image);
    }
  }, [categoryData]);

  useEffect(() => {
    setPreviewImage(image ? URL.createObjectURL(image) : "");
  }, [image]);

  return (
    <Modal show={AddCate} size="md" centered onHide={closeModal}>
      <Modal.Header className="modalheader text-center">
        Add Category
        <span onClick={closeModal}>
          <CloseIcon />
        </span>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col d-flex justify-content-center">
            <div className="profile-Logo">
              {previewImage ? (
                <div className="groupEditLogo position-relative ">
                  <img src={previewImage} alt="image" />
                  <span
                    className="closeicon position-absolute"
                    onClick={() => {
                      setImage(null);
                      setPreviewImage("");
                    }}
                  >
                    <DeleteIcon />
                  </span>
                </div>
              ) : (
                <DropZone
                  edit={true}
                  product={false}
                  onFileUpload={(file) => {
                    setImage(file);
                  }}
                />
              )}
              <p>Category Image</p>
            </div>
          </div>
        </div>
        <div className="createform">
          <div className="form-group">
            <p>
              Name <span> * </span>
            </p>
            <TextField
              name="name"
              variant="outlined"
              fullWidth
              placeholder="Enter Name"
              size="small"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </div>
          <div className="form-group des">
            <p>Description</p>
            <TextField
              name="name"
              variant="outlined"
              fullWidth
              placeholder="Enter Description"
              multiline
              rows={4}
              className="dessss"
              size="small"
              value={formData.description}
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
            />
          </div>
          <div className="form-group selectall-option">
            <p>Status</p>
            <div className="d-flex align-items-center justify-content-start">
              <FormControlLabel
                className="me-0"
                name="active"
                checked={formData.active}
                onChange={(e) => setFormData({ ...formData, active: e.target.checked })}
                control={<IOSSwitch sx={{ m: 1 }} />}
              />
              <span className={formData.active ? "active-label" : "inactive-label"}>
                {formData.active ? "Active" : "Inactive"}
              </span>
            </div>
          </div>
          {error && <p className="errormessage">Fill all required inputs</p>}
          <div className="modal-footer-btn mt-3 mb-1">
            <button className="createbtn cancelbtn" onClick={closeModal}>
              Cancel
            </button>
            {!formLoading ? (
              <button className="createbtn" onClick={submitHandler}>
                Save
              </button>
            ) : (
              <button className="loading save">
                <svg viewBox="25 25 50 50">
                  <circle r="20" cy="50" cx="50">
                    {""}
                  </circle>
                </svg>
              </button>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCategory;
