import React from "react";
import ReactApexChart from "react-apexcharts";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { PLATFORM_TYPE } from "../../utils/common";

const PlatformUsage = ({ graphStats }) => {
  let { width } = useWindowDimensions();
  const platforms = Object.keys(PLATFORM_TYPE || {});
  const platformCounts = Object.keys(PLATFORM_TYPE).map((key) => graphStats[key] || 0);

  const chartData = {
    series: [
      {
        name: "Count",
        data: platformCounts,
      },
    ],
    options: {
      chart: {
        type: "radar",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        radar: {
          offsetY: width > 1400 && width < 1700 ? 20 : width >= 1700 ? 20 : 15,
          offsetX: width > 1400 && width < 1700 ? -5 : width >= 1700 ? -5 : -5,
          size: width > 1400 && width < 1700 ? 80 : width >= 1700 ? 100 : 70,
          polygons: {
            fill: {
              colors: ["#f8f8f8"],
            },
          },
          curve: "smooth",
        },
      },
      colors: ["#CA353B"],
      fill: "solid",
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      xaxis: {
        categories: platforms,
        labels: {
          style: {
            colors: ["#333", "#333", "#333"],
            fontSize: width > 1400 && width < 1700 ? 9 : width >= 1700 ? 11 : 8,
            fontFamily: "Poppins, sans-serif",
            fontWeight: "600",
          },
        },
      },
      yaxis: {
        show: false,
        tickAmount: 5,
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="radar"
        height={width > 1400 && width < 1650 ? 200 : width >= 1650 ? 260 : 190}
      />
    </div>
  );
};

export default PlatformUsage;
