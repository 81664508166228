import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  product: 1,
  rootCategory: 1,
  categoryTree: [],
};

const pageSlice = createSlice({
  name: "pageSlice",
  initialState,
  reducers: {
    saveProductPage: (state, action) => {
      state.product = action.payload;
    },
    saveCategoryTree: (state, action) => {
      state.categoryTree = action.payload;
    },
    saveRootCategory: (state, action) => {
      state.rootCategory = action.payload;
    },
  },
});

export const { saveProductPage, saveRootCategory, saveCategoryTree } = pageSlice.actions;
export default pageSlice.reducer;
