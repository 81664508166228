import React from "react";
import ReactApexChart from "react-apexcharts";
import NoChartImage from "../../Assets/images/no-chart-data.png";
import useWindowDimensions from "../../utils/useWindowDimensions";

const findGroupNameRecursively = (data, uuid) => {
  for (const item of data || []) {
    if (item.uuid === uuid) {
      return item;
    }
    if (item?.children?.length > 0) {
      const found = findGroupNameRecursively(item.children, uuid);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

const StateWise = ({ groupHierarchy, graphStats }) => {
  let { width } = useWindowDimensions();

  const staticData = Object.keys(graphStats || {})?.map((uuid) => ({
    name: findGroupNameRecursively([groupHierarchy], uuid)?.name || "",
    count: graphStats[uuid] || 0,
  }));

  const chartData = {
    series: [
      {
        data: staticData?.map((item) => ({
          x: item?.name,
          y: item?.count,
        })),
      },
    ],
    options: {
      legend: {
        show: false,
      },
      chart: {
        type: "treemap",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "12px",
        },
      },
      plotOptions: {
        treemap: {
          enableShades: true,
          shadeIntensity: 0.5,
          reverseNegativeShade: true,
          colorScale: {
            ranges: [
              {
                from: 0,
                to: 150,
                color: "#333333",
              },
              {
                from: 150,
                to: 600,
                color: "#ca373c",
              },
            ],
          },
        },
      },
    },
  };

  const chartHeight = width > 1400 && width < 1650 ? 210 : width >= 1650 ? 270 : 200;

  return (
    <>
      <div id="chart">
        {staticData?.reduce((init, item) => init + item.count, 0) !== 0 ? (
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="treemap"
            height={width > 1400 && width < 1650 ? 210 : width >= 1650 ? 270 : 200}
          />
        ) : (
          <div className="no-chart-data" style={{ height: chartHeight }}>
            <img src={NoChartImage} />
            <h5>No Data Yet</h5>
          </div>
        )}
      </div>
    </>
  );
};

export default StateWise;
