import React, { useEffect, useState } from "react";
import { CategoriesSideIcon, MyDashBoardIcon, ProductsIcon } from "../../Assets/Icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = ({ sideBar, setSideBar }) => {
  const [activeTab, setActiveTab] = useState();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user);
  const groupId = userData?.user?.group?.uuid;

  useEffect(() => {
    const path = window.location.pathname;
    let active = "";
    if (path.startsWith("/categories")) active = "categories";
    else if (path.startsWith("/products")) active = "products";
    else if (path.startsWith("/dashboard")) active = "dashboard";
    setActiveTab(active);
  }, [window.location.pathname]);

  return (
    <div className="mainside">
      <div className={sideBar ? "sidmenu active" : "sidmenu"}>
        <div className="mainsidelist">
          <ul>
            <li
              className={`${activeTab == "dashboard" ? "active" : ""}`}
              onClick={() => {
                navigate(`/dashboard/${groupId}`);
                setActiveTab("dashboard");
              }}
            >
              <MyDashBoardIcon />
              <p className="ms-3">Dashboard</p>
            </li>
            <li
              className={`${activeTab == "categories" ? "active" : ""}`}
              onClick={() => {
                navigate("/categories");
                setActiveTab("categories");
              }}
            >
              <CategoriesSideIcon />
              <p className="ms-3">Categories</p>
            </li>
            <li
              className={`${activeTab == "products" ? "active" : ""}`}
              onClick={() => {
                navigate("/products");
                setActiveTab("products");
              }}
            >
              <ProductsIcon />
              <p className="ms-3">Products</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
