import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apps: [],
  tags: {
    data: {
      id: 0,
      name: "",
      parentId: null,
      metaData: null,
      createdAt: "",
      updatedAt: "",
      tags: [],
      children: [],
    },
    status: true,
  },
};

const applicationSlice = createSlice({
  name: "applicationSlice",
  initialState,
  reducers: {
    myApplicationList: (state, action) => {
      state.apps = action.payload;
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    },
  },
});

export const { myApplicationList, setTags } = applicationSlice.actions;
export default applicationSlice.reducer;
