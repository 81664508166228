import React from "react";
import Modal from "react-bootstrap/Modal";
import { DownloadIcon } from "../../Assets/Icons";

const ImageView = ({ show, handleClose, snapShot }) => {
  const download = async () => {
    const originalImage = snapShot;
    const image = await fetch(originalImage);

    // Split image name
    const nameSplit = originalImage.split("/");
    const duplicateName = nameSplit.pop();

    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "" + duplicateName + "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal centered className="snapShots" size="lg" show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="image-here">
          <img src={snapShot} alt="image" />
          <span className="action-btns" onClick={download}>
            <DownloadIcon /> Download
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ImageView;
