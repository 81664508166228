import React, { useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { myApplicationList } from "../../store/slice/applicationSlice";

const DefaultLayout = ({ sideBar, setSideBar }) => {
  const configData = useSelector((state) => state?.config);
  const userData = useSelector((state) => state?.user);
  const dispatch = useDispatch();

  const fetchApplication = async () => {
    try {
      var { data } = await axios.get(`${configData?.config?.UNO_URL}/api/application/mine`, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      dispatch(myApplicationList(data?.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (configData?.config?.UNO_URL && userData?.token) {
      fetchApplication();
    }
  }, [configData?.config?.UNO_URL, userData?.token]);

  return (
    <>
      <Header sideBar={sideBar} setSideBar={setSideBar} />
      <Sidebar sideBar={sideBar} setSideBar={setSideBar} />
    </>
  );
};

export default DefaultLayout;
